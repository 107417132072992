export const ChatGroupsData = [
	{
		id: 1,
		name: 'Figma Design Group',
		groupMembers: [
			{
				userId: 5
			},
			{
				userId: 6
			},
			{
				userId: 8
			},
			{
				userId: 21
			}
		]
	}
];
export default ChatGroupsData;
