export const MessagesData = [
	{
		id: 1,
		chatMessages: [
			{
				userId: 21,
				message: 'Hi',
				date: 'June 17, 2022',
				time: '09:39 AM'
			},
			{
				userId: 1,
				message: 'Hello. How can I help You?',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 21,
				message: 'Can I get details of my last transaction ?',
				date: 'June 17, 2022',
				time: '09:39 AM'
			},
			{
				userId: 1,
				message: 'Let me check',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 1,
				message: 'You have paid $49 for purchasing Geeks-React Template',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 21,
				message: 'Ok Thanks',
				date: 'June 17, 2022',
				time: '09:39 AM'
			}
		]
	},
	{
		id: 2,
		chatMessages: [
			{
				userId: 2,
				message:
					'I just start Bootstrap, SCSS and Gulp development & setup github repository.',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 21,
				message:
					'I also have start Bootstrap and SCSS & setup github repository.',
				date: 'June 17, 2022',
				time: '09:39 AM'
			},
			{
				userId: 2,
				message: 'Wow!! Gr8',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 2,
				message: 'Best Luck :)',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 21,
				message: 'Thank you',
				date: 'June 17, 2022',
				time: '09:39 AM'
			},
			{
				userId: 2,
				message: 'You are most welcome.',
				date: 'June 17, 2022',
				time: '09:35 AM'
			}
		]
	},
	{
		id: 3,
		chatMessages: [
			{
				userId: 2,
				message: 'How do you do ?',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 21,
				message:
					'I just start Bootstrap, SCSS and Gulp development & setup github repository.',
				date: 'June 17, 2022',
				time: '09:39 AM'
			}
		]
	},
	{
		id: 4,
		chatMessages: [
			{
				userId: 3,
				message: 'Hi',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 21,
				message:
					'I just start Bootstrap, SCSS and Gulp development & setup github repository.',
				date: 'June 17, 2022',
				time: '09:39 AM'
			}
		]
	},
	{
		id: 5,
		chatMessages: [
			{
				userId: 4,
				message: "What's up",
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 21,
				message:
					'I just start Bootstrap, SCSS and Gulp development & setup github repository.',
				date: 'June 17, 2022',
				time: '09:39 AM'
			}
		]
	},
	{
		id: 6,
		chatMessages: [
			{
				userId: 5,
				message:
					'I just start Bootstrap, SCSS and Gulp development & setup github repository. I just start Bootstrap, SCSS and Gulp development & setup github repository.',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 21,
				message:
					'I just start Bootstrap, SCSS and Gulp development & setup github repository.',
				date: 'June 17, 2022',
				time: '09:39 AM'
			}
		]
	},
	{
		id: 7,
		chatMessages: [
			{
				userId: 6,
				message: `I just start Bootstrap, SCSS and Gulp development & setup github repository. I just start Bootstrap, SCSS and Gulp development & setup github repository.
        I just start Bootstrap, SCSS and Gulp development & setup github repository. I just start Bootstrap, SCSS and Gulp development & setup github repository.`,
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 21,
				message:
					'I just start Bootstrap, SCSS and Gulp development & setup github repository.',
				date: 'June 17, 2022',
				time: '09:39 AM'
			}
		]
	},
	{
		id: 8,
		chatMessages: [
			{
				userId: 7,
				message: 'Hello',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 21,
				message: 'How are you?',
				date: 'June 17, 2022',
				time: '09:39 AM'
			}
		]
	},
	{
		id: 9,
		chatMessages: []
	},
	{
		id: 10,
		chatMessages: [
			{
				userId: 9,
				message: 'Hey',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 21,
				message: 'Yes !',
				date: 'June 17, 2022',
				time: '09:39 AM'
			}
		]
	},
	{
		id: 11,
		chatMessages: [
			{
				userId: 10,
				message: 'Hello',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 21,
				message: 'Hi !',
				date: 'June 17, 2022',
				time: '09:39 AM'
			}
		]
	},
	{
		id: 12,
		chatMessages: [
			{
				userId: 11,
				message: 'How can I help you?',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 21,
				message: 'I just started Bootstrap, have some queries...',
				date: 'June 17, 2022',
				time: '09:39 AM'
			}
		]
	},
	{
		id: 13,
		chatMessages: [
			{
				userId: 12,
				message: 'How do you do?',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 21,
				message: 'I am doing good :)',
				date: 'June 17, 2022',
				time: '09:39 AM'
			}
		]
	},
	{
		id: 14,
		chatMessages: [
			{
				userId: 13,
				message: 'Hey!',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 21,
				message:
					'I just start Bootstrap, SCSS and Gulp development & setup github repository.',
				date: 'June 17, 2022',
				time: '09:39 AM'
			}
		]
	},
	{
		id: 15,
		chatMessages: [
			{
				userId: 14,
				message: 'Hello!',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 21,
				message:
					'I just start Bootstrap, SCSS and Gulp development & setup github repository.',
				date: 'Jun 4, 2022',
				time: '09:39 AM'
			}
		]
	},
	{
		id: 16,
		chatMessages: [
			{
				userId: 5,
				message: 'Hello, everyone',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 6,
				message: 'Hi Olivia Cooper',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 8,
				message: 'How are you doing guys ?',
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 21,
				message: 'Hey, wow! all are online together, gr8, I have a great news ',
				date: 'June 17, 2022',
				time: '09:39 AM'
			},
			{
				userId: 8,
				message: "What's that ?",
				date: 'June 17, 2022',
				time: '09:35 AM'
			},
			{
				userId: 21,
				message: 'Geeks-React theme is now avialable with chat component.',
				date: 'June 17, 2022',
				time: '09:39 AM'
			},
			{
				userId: 5,
				message: "Wow!!! that's really great news",
				date: 'June 17, 2022',
				time: '09:35 AM'
			}
		]
	}
];

export default MessagesData;
