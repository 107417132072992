// import media files
import Avatar1 from 'assets/images/avatar/avatar-1.jpg';
import Avatar2 from 'assets/images/avatar/avatar-2.jpg';
import Avatar3 from 'assets/images/avatar/avatar-3.jpg';
import Avatar4 from 'assets/images/avatar/avatar-4.jpg';
import Avatar5 from 'assets/images/avatar/avatar-5.jpg';
import Avatar6 from 'assets/images/avatar/avatar-6.jpg';
import Avatar7 from 'assets/images/avatar/avatar-7.jpg';
import Avatar8 from 'assets/images/avatar/avatar-8.jpg';
import Avatar9 from 'assets/images/avatar/avatar-9.jpg';
import Avatar10 from 'assets/images/avatar/avatar-10.jpg';
import Avatar15 from 'assets/images/avatar/avatar-15.jpg';
import Avatar12 from 'assets/images/avatar/avatar-12.jpg';
import Avatar13 from 'assets/images/avatar/avatar-13.jpg';
import Avatar14 from 'assets/images/avatar/avatar-14.jpg';

export const allposts = [
	{
		id: 1,
		category: 'Courses',
		title: 'Revolutionize how you build the web',
		type: 'image',
		date: '7 July, 2021 1:42pm',
		instructor_name: 'Reva Yokk',
		instructor_image: Avatar7,
		status: 'Draft',
		action: 2
	},
	{
		id: 2,
		category: 'Marketing',
		type: 'video',
		title: 'Guide to Static Sites with Gatsby',
		date: '6 July, 2021 2:42pm',
		instructor_name: 'Brooklyn Simmons',
		instructor_image: Avatar6,
		status: 'Draft',
		action: 2
	},
	{
		id: 3,
		category: 'Workshop',
		type: 'image',
		title: 'The Modern HTML Courses',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Miston Wilson',
		instructor_image: Avatar5,
		status: 'Published',
		action: 2
	},
	{
		id: 4,
		category: 'Company',
		type: 'image',
		title: 'Courses JavaScript Heading Title',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Guy Hawkins',
		instructor_image: Avatar1,
		status: 'Published',
		action: 1
	},
	{
		id: 5,
		category: 'Workshop2',
		type: 'link',
		title: 'Get Start with Node Heading Title',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Sina Ray',
		instructor_image: Avatar3,
		status: 'Scheduled',
		action: 1
	},
	{
		id: 6,
		category: 'Marketing',
		type: 'video',
		title: 'Get Start with Laravel',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Sobo Rikhan',
		instructor_image: Avatar9,
		status: 'Scheduled',
		action: 1
	},
	{
		id: 7,
		category: 'Courses',
		type: 'image',
		title: 'Get Start with React',
		date: '4 July, 2021 12:42pm',
		instructor_name: 'April Noms',
		instructor_image: Avatar2,
		status: 'Published',
		action: 1
	},
	{
		id: 8,
		category: 'Workshop',
		type: 'link',
		title: 'Get Start with Angular',
		date: '3 July, 2021 10:42am',
		instructor_name: 'Jacob Jones',
		instructor_image: Avatar4,
		status: 'Deleted',
		action: 2
	},
	{
		id: 9,
		category: 'Marketing',
		type: 'video',
		title: 'Get Start with Laravel',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Sobo Rikhan',
		instructor_image: Avatar9,
		status: 'Deleted',
		action: 1
	},
	{
		id: 10,
		category: 'Workshop2',
		type: 'image',
		title: 'Get Start with Node Heading Title',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Sina Ray',
		instructor_image: Avatar3,
		status: 'Deleted',
		action: 1
	},
	{
		id: 11,
		category: 'Company',
		type: 'link',
		title: 'Courses JavaScript Heading Title',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Guy Hawkins',
		instructor_image: Avatar14,
		status: 'Published',
		action: 1
	},
	{
		id: 12,
		category: 'Marketing',
		type: 'video',
		title: 'Get Start with Laravel',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Sobo Rikhan',
		instructor_image: Avatar8,
		status: 'Published',
		action: 1
	},
	{
		id: 13,
		category: 'Courses',
		type: 'image',
		title: 'Get Start with React',
		date: '4 July, 2021 12:42pm',
		instructor_name: 'April Noms',
		instructor_image: Avatar2,
		status: 'Draft',
		action: 1
	},
	{
		id: 14,
		category: 'Workshop',
		type: 'link',
		title: 'Get Start with Angular',
		date: '3 July, 2021 10:42am',
		instructor_name: 'Jacob Jones',
		instructor_image: Avatar15,
		status: 'Draft',
		action: 2
	},
	{
		id: 15,
		category: 'Courses',
		type: 'video',
		title: 'Revolutionize how you build the web',
		date: '7 July, 2021 1:42pm',
		instructor_name: 'Reva Yokk',
		instructor_image: Avatar7,
		status: 'Draft',
		action: 2
	},
	{
		id: 16,
		category: 'Marketing',
		type: 'image',
		title: 'Guide to Static Sites with Gatsby',
		date: '6 July, 2021 2:42pm',
		instructor_name: 'Brooklyn Simmons',
		instructor_image: Avatar13,
		status: 'Scheduled',
		action: 2
	},
	{
		id: 17,
		category: 'Workshop',
		type: 'link',
		title: 'Get Start with Angular',
		date: '3 July, 2021 10:42am',
		instructor_name: 'Jacob Jones',
		instructor_image: Avatar4,
		status: 'Scheduled',
		action: 2
	},
	{
		id: 18,
		category: 'Marketing',
		type: 'video',
		title: 'Get Start with Laravel',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Sobo Rikhan',
		instructor_image: Avatar12,
		status: 'Draft',
		action: 1
	},
	{
		id: 19,
		category: 'Workshop2',
		type: 'link',
		title: 'Get Start with Node Heading Title',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Sina Ray',
		instructor_image: Avatar3,
		status: 'Scheduled',
		action: 1
	},
	{
		id: 20,
		category: 'Company',
		type: 'video',
		title: 'Courses JavaScript Heading Title',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Guy Hawkins',
		instructor_image: Avatar10,
		status: 'Draft',
		action: 1
	}
];

// All Published Post list

export const allPublishedPosts = [
	{
		id: 3,
		category: 'Workshop',
		type: 'image',
		title: 'The Modern HTML Courses',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Miston Wilson',
		instructor_image: Avatar5,
		status: 'Published',
		action: 2
	},
	{
		id: 4,
		category: 'Company',
		type: 'image',
		title: 'Courses JavaScript Heading Title',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Guy Hawkins',
		instructor_image: Avatar1,
		status: 'Published',
		action: 1
	},
	{
		id: 7,
		category: 'Courses',
		type: 'image',
		title: 'Get Start with React',
		date: '4 July, 2021 12:42pm',
		instructor_name: 'April Noms',
		instructor_image: Avatar2,
		status: 'Published',
		action: 1
	},
	{
		id: 11,
		category: 'Company',
		type: 'link',
		title: 'Courses JavaScript Heading Title',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Guy Hawkins',
		instructor_image: Avatar14,
		status: 'Published',
		action: 1
	},
	{
		id: 12,
		category: 'Marketing',
		type: 'video',
		title: 'Get Start with Laravel',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Sobo Rikhan',
		instructor_image: Avatar8,
		status: 'Published',
		action: 1
	}
];

// All Scheduled Post List

export const allScheduledPosts = [
	{
		id: 5,
		category: 'Workshop2',
		type: 'link',
		title: 'Get Start with Node Heading Title',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Sina Ray',
		instructor_image: Avatar3,
		status: 'Scheduled',
		action: 1
	},
	{
		id: 6,
		category: 'Marketing',
		type: 'video',
		title: 'Get Start with Laravel',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Sobo Rikhan',
		instructor_image: Avatar9,
		status: 'Scheduled',
		action: 1
	},
	{
		id: 16,
		category: 'Marketing',
		type: 'image',
		title: 'Guide to Static Sites with Gatsby',
		date: '6 July, 2021 2:42pm',
		instructor_name: 'Brooklyn Simmons',
		instructor_image: Avatar13,
		status: 'Scheduled',
		action: 2
	},
	{
		id: 17,
		category: 'Workshop',
		type: 'link',
		title: 'Get Start with Angular',
		date: '3 July, 2021 10:42am',
		instructor_name: 'Jacob Jones',
		instructor_image: Avatar4,
		status: 'Scheduled',
		action: 2
	},
	{
		id: 19,
		category: 'Workshop2',
		type: 'link',
		title: 'Get Start with Node Heading Title',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Sina Ray',
		instructor_image: Avatar3,
		status: 'Scheduled',
		action: 1
	}
];

// All Draft Posts List

export const allDraftPosts = [
	{
		id: 1,
		category: 'Courses',
		title: 'Revolutionize how you build the web',
		type: 'image',
		date: '7 July, 2021 1:42pm',
		instructor_name: 'Reva Yokk',
		instructor_image: Avatar7,
		status: 'Draft',
		action: 2
	},
	{
		id: 2,
		category: 'Marketing',
		type: 'video',
		title: 'Guide to Static Sites with Gatsby',
		date: '6 July, 2021 2:42pm',
		instructor_name: 'Brooklyn Simmons',
		instructor_image: Avatar6,
		status: 'Draft',
		action: 2
	},
	{
		id: 13,
		category: 'Courses',
		type: 'image',
		title: 'Get Start with React',
		date: '4 July, 2021 12:42pm',
		instructor_name: 'April Noms',
		instructor_image: Avatar2,
		status: 'Draft',
		action: 1
	},
	{
		id: 14,
		category: 'Workshop',
		type: 'link',
		title: 'Get Start with Angular',
		date: '3 July, 2021 10:42am',
		instructor_name: 'Jacob Jones',
		instructor_image: Avatar15,
		status: 'Draft',
		action: 2
	},
	{
		id: 15,
		category: 'Courses',
		type: 'video',
		title: 'Revolutionize how you build the web',
		date: '7 July, 2021 1:42pm',
		instructor_name: 'Reva Yokk',
		instructor_image: Avatar7,
		status: 'Draft',
		action: 2
	},
	{
		id: 18,
		category: 'Marketing',
		type: 'video',
		title: 'Get Start with Laravel',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Sobo Rikhan',
		instructor_image: Avatar12,
		status: 'Draft',
		action: 1
	},
	{
		id: 20,
		category: 'Company',
		type: 'video',
		title: 'Courses JavaScript Heading Title',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Guy Hawkins',
		instructor_image: Avatar10,
		status: 'Draft',
		action: 1
	}
];

// all Deleted Post List

export const allDeletedPosts = [
	{
		id: 8,
		category: 'Workshop',
		type: 'link',
		title: 'Get Start with Angular',
		date: '3 July, 2021 10:42am',
		instructor_name: 'Jacob Jones',
		instructor_image: Avatar4,
		status: 'Deleted',
		action: 2
	},
	{
		id: 9,
		category: 'Marketing',
		type: 'video',
		title: 'Get Start with Laravel',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Sobo Rikhan',
		instructor_image: Avatar9,
		status: 'Deleted',
		action: 1
	},
	{
		id: 10,
		category: 'Workshop2',
		type: 'image',
		title: 'Get Start with Node Heading Title',
		date: '5 July, 2021 5:42pm',
		instructor_name: 'Sina Ray',
		instructor_image: Avatar3,
		status: 'Deleted',
		action: 1
	}
];

export const AllPostsData = [
	allposts,
	allPublishedPosts,
	allScheduledPosts,
	allDraftPosts,
	allDeletedPosts
];
