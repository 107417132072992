// import media files
import Avatar1 from 'assets/images/avatar/avatar-1.jpg';
import Avatar2 from 'assets/images/avatar/avatar-2.jpg';
import Avatar3 from 'assets/images/avatar/avatar-3.jpg';
import Avatar4 from 'assets/images/avatar/avatar-4.jpg';
import Avatar5 from 'assets/images/avatar/avatar-5.jpg';
import Avatar6 from 'assets/images/avatar/avatar-6.jpg';
import Avatar7 from 'assets/images/avatar/avatar-7.jpg';
import Avatar8 from 'assets/images/avatar/avatar-8.jpg';

export const TestimonialsList = [
	{
		id: 1,
		name: 'Barry Watson',
		designation: 'Web Developer,UK',
		image: Avatar1,
		content:
			'The generated lorem Ipsum is therefore always free from repetition, injected humour, or words etc generate lorem Ipsum which looks racteristic reasonable.',
		rating: 5.0,
		color: 'primary'
	},
	{
		id: 2,
		name: 'Linda Shenoy',
		designation: 'Developer and Bootcamp Instructor',
		image: Avatar2,
		content:
			'Lorem ipsum dolor sit amet, consectetur adipi scing elit. Sed vel felis imperdiet, lacinia metus malesuada diamamus rutrum turpis leo, id tincidunt magna sodales.',
		rating: 5.0,
		color: 'info'
	},
	{
		id: 3,
		name: 'Jean Watson',
		designation: 'Engineering Architect',
		image: Avatar3,
		content:
			'Sed pretium risus magna, ac efficitur nunc rutrum imperdiet. Vivamus sed ante sed mi fermentum tempus. Nullam finibus augue eget felis efficitur semper.',
		rating: 5.0,
		color: 'danger'
	},

	{
		id: 4,
		name: 'John Deo',
		designation: 'Web Developer,UK',
		image: Avatar4,
		content:
			'Morbi quis posuere lacus. Morbi et metus sit amet tellus dapibus aliquam. Morbi consectetur magna vel turpis lobortis lorem iopsum dolor sit commodo.',
		rating: 4.5,
		color: 'success'
	},
	{
		id: 5,
		name: 'Rubik Nanda',
		designation: 'Web Developer,UK',
		image: Avatar5,
		content:
			'Curabitur sollicitudin mi et sagittis egestas. Curabitur pellentesque nibh id enim hendrerit, at mollis neque rutrum. Sed nibh velit, tristique et dolor vitae.',
		rating: 4.5,
		color: 'secondary'
	},
	{
		id: 6,
		name: 'Barry Watson',
		designation: 'Web Developer,UK',
		image: Avatar6,
		content:
			'Vestibulum in lobortis purus. Quisque sem turpis, hendrerit quis lacinia nec, rutrum nec velit. Nullam lobortis rhoncus tincidunt lorem ispun dnascetur ridiculus mus.',
		rating: 4.5,
		color: 'info'
	},
	{
		id: 7,
		name: 'Jean Watson',
		designation: 'Web Developer,UK',
		image: Avatar7,
		content:
			'Praesent sit amet ornare magna, vitae consequat arcu. Vestibulum at dictum erat, a fringilla ante. Nam et nibh ut nunc rutrum suscipit quis non neque. Nulla facilisi.',
		rating: 4.5,
		color: 'warning'
	},
	{
		id: 8,
		name: 'Barry Watson',
		designation: 'Engineering Architect',
		image: Avatar8,
		content:
			'Sed pretium risus magna, ac efficitur nunc rutrum imperdiet. Vivamus sed ante sed mi fermentum tempus. Nullam finibus augue eget felis efficitur semper.',
		rating: 4.5,
		color: 'dark'
	}
];

export default TestimonialsList;
